:root {
    --dark-bg: #0D0D0D ;
    --white-color: #fff;
}
body.dark {
    background-color: var(--dark-bg) !important;
}
 body.dark *{
    color: var(--white-color);
}
body.dark .table , 
body.dark .table thead,
body.dark .table thead tr ,
body.dark .table thead tr th , 
body.dark .table tbody tr , 
body.dark .table tbody tr td {
    background-color: transparent !important;
}
body.dark option {
    color: var(--dark-bg);
}
/* start header */
body.dark header.header div.navigation .logo .light-logo {
    display: block !important;
}
body.dark header.header div.navigation .logo .dark-logo {
    display: none;
}
body.dark .navigation{
    background-color: var(--dark-bg) !important;
}
body.dark .navigation *{
    color: var(--white-color) !important;
}
body.dark div.navigation .nav-links .dark-light-mode-btn span.active i {
    color: var(--dark-bg) !important;
}
body.dark header.header div.navigation .nav-links .links li:not(:last-child)::after {
    background-color: var(--white-color) !important;
}
body.dark .mobile-menu-links {
    background-color: var(--dark-bg) !important;
    color: var(--white-color);
}
body.dark .mobile-menu-links .collapse-menu + .menu {
    background-color: #131313;
}
@media(max-width:767px) {
    body.dark header.header {
        background-color: var(--dark-bg) !important;
        color: var(--white-color);
    }
    body.dark .mobile-menu-links a{
        color: var(--white-color) !important;
    }
    body.dark .social-media{
        background-color: #0D0D0D66 !important;
    }
}
/* end header */
/* start home page */
body.dark .social-media{
    background-color: var(--dark-bg) !important;
}
body.dark .features .feature , 
body.dark .features .feature i{
    color: var(--white-color);
}
body.dark .table {
    background-color: var(--dark-bg) !important;
    color: var(--white-color);
}
/* start features section */
body.dark .features .feature i{
    color: var(--white-color) ;
}
/* end home page */
/* start auth page */
body.dark .auth-layout .left-image .back-btn  {
    background-color: var(--dark-bg);
}
body.dark .login-section  ,
body.dark .auth-layout .right  .register-section{
    background-color: var(--dark-bg) !important;
}
body.dark .auth-layout .right .login-section .login-form > div , 
body.dark .input-style{
    background-color: #26262680;
}
body.dark .auth-layout .right .login-section .login-form > div input::placeholder ,
body.dark .input-style input::placeholder {
    color: #FFFFFF4D;
}
body.dark .register-section .upload-user-image p.add-new-photo {
    background-color: #002E63;
    color:#1C86FF ;
}
/* end auth page */
/* start courses page */
body.dark .courses-title h5{
    color: var(--dark-bg);
}
body.dark .course-box h6 , 
body.dark .course-box p , 
body.dark .course-box i {
    color: var(--white-color) !important;
}
body.dark .course-box .add-course-icon i{
    color: var(--dark-bg) !important;
}
body.dark .courses-search-bar .search-box {
    background-color: #26262680;
}
body.dark .courses-search-bar .search-box input::placeholder {
    color: #FFFFFF4D;
}
/* end courses page */
/* start course preview page */
body.dark .details-box {
    background-color: var(--dark-bg);
}
body.dark .course-details .details-box .course-title-price , 
body.dark .course-content-collapse .top-bar ,
body.dark .course-content-collapse .collapsed-data.open{
    border-color: #FFFFFF80 ;
}
body.dark .details-box p:not(.price) ,
body.dark .details-box i {
    color: #FFFFFF80 !important;
}

/* end course preview page */
/* start profile page */
body.dark .profile-layout .profile-section .group input {
    background-color: #26262680;
}
body.dark .profile-layout .profile-section .group h6 {
    color: var(--white-color);
}
body.dark  .profile-layout .user-profile-sidebar .profile-links li i {
    color: var(--white-color);
}
body.dark svg text {
    fill: var(--white-color) !important;
}
body.dark .profile-layout .my-courses-section .row .my-course-box p  , 
body.dark .profile-layout .my-courses-section .row .my-course-box .course-time .time i {
    color: #FFFFFF80;
}
body.dark .profile-layout .my-courses-section .row .my-course-box > div:not(:last-child) {
    border-color: #FFFFFF33;
}
/* end profile page */
/* start my courses page */
body.dark .my-course-watch-page .lesson-info .lesson-description {
    background-color: #1F1F1F;
}
/* end my courses page */
/* start quiz page */
body.dark .quiz-area-container .quiz-area {
    background-color: #0D0D0D;
}
body.dark .quiz-area-container .quiz-area .question .answers .answer {
    background-color: #7f8c8d59;
    margin-bottom: 10px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
body.dark .breadcrumb-exit-container .exit-quiz {
    background-color: transparent;
}
body.dark .breadcrumb-exit-container .exit-quiz:hover {
    background-color:#FF3838 ;
    color: #fff;
  }
/* end quiz page */
/* start dashboard */
body.dark .dashboard-header {
    background-color: #111111B2;
}
body.dark .dashboard-header .search-theme .search-input {
    border-color: #FFFFFF33 !important;
    background-color: #0D0D0D !important;
}
body.dark .dashboard-header .search-theme .search-input i {
    color: #FFFFFF !important;
}
body.dark header.dashboard-header>div .search-theme .search-input button.search-btn {
    border-color: #FFFFFF33 !important;
}
body.dark header.dashboard-header>div .search-theme .dark-theme {
    background-color: #333333 !important;
    color: #fff !important;
}
body.dark .dashboard-sidebar {
    background-color: #0D0D0DB2 !important;
}
/* start dashboard home */
body.dark .analysis .right.students .content {
    background-color: #0D0D0D99;
}
body.dark .analysis .right.students .content .student-row {
    background-color: #111111;
}
body.dark .dashboard-home-section h6.title {
    color: #E1E1E1B2 !important;
}
body.dark .dashboard-students-section .head h6  {
    color: #E1E1E1;
}
body.dark .student-table thead , 
body.dark .student-table tbody {
    background-color: #0D0D0D99 !important;
}
body.dark .student-table thead * , 
body.dark .student-table tbody tr * ,
body.dark .dashboard-categories-section .categories-create-new .category * ,
body.dark .dashboard-categories-section .categories-create-new .new-category * {
    color: #E1E1E1 !important;
}
body.dark .student-table tbody tr  {
    background-color: #111111;
}
body.dark .student-details-section .courses-homework .courses .content ,
body.dark .student-details-section .courses-homework .home-work-marks .content .box ,
body.dark .student-details-section .student-homeworks table {
    background-color: #0D0D0D99 !important;
}
body.dark .student-details-section .courses-homework .courses .content .course {
    background-color: #0D0D0D !important;
}
body.dark .student-details-section .courses-homework .courses .content .course div p{
    color: #FFFFFF80 !important;
}
body.dark .student-details-section .student-homeworks table tbody tr  {
    background-color: #111111 !important;
}
body.dark .dashboard-categories-section .categories-create-new .category .top {
    background-color: #111111 !important;
}
body.dark .dashboard-categories-section .categories-create-new .category .bottom  {
    background-color: #0D0D0D99 !important;
}
body.dark .dashboard-categories-section .categories-create-new .category .top .icon  {
    color: #fff;
    background-color: #1D1D1D;
}
body.dark .dashboard-categories-section .categories-create-new .new-category  {
    background-color: #0D0D0D38 !important;
    border-color: #0D0D0D !important;
}
body.dark .categories-filter-menu  {
    background-color: #0D0D0D80 !important;
}
body.dark .categories-filter-menu .category-type , 
body.dark .categories-filter-menu .category-type div button,
body.dark .categories-filter-menu .school-year div button  {
    border-color: #FFFFFF33 !important;
}
body.dark .dashboard-courses h1 , 
body.dark .dashboard-units-page h1,
body.dark .dashboard-lessons-page h1,
body.dark .dashboard-videos-page h1,
body.dark .dashboard-exams-page h1 ,
body.dark .dashboard-subscriptions-invoices h1,
body.dark .dashboard-create-copon .page-header ,
body.dark .dashboard-create-copon .page-header a.prev-copons ,
body.dark .dashboard-add-user-page h1 ,
body.dark .dashboard-notifications-page h1,
body.dark .dashboard-attachments-page h1{
    border-color: #FFFFFF1A !important;
}
body.dark .dashboard-courses .add-new-course , 
body.dark .dashboard-units-page .new-unit,
body.dark .dashboard-lessons-page .new-lesson,
body.dark .dashboard-videos-page .new-video .bottom , 
body.dark .dashboard-videos-page .new-video .top ,
body.dark .dashboard-exams-page .new-exam ,
body.dark .dashboard-subscriptions-invoices .invoices .add-new-invoice .new-invoice   {
    background-color: #0D0D0D !important;
    border-color: #0000000D !important;
}
body.dark .dashboard-courses .add-new-course .course-image , 
body.dark .dashboard-courses .add-new-course .course-title-desc input,
body.dark .dashboard-courses .add-new-course .course-title-desc textarea ,
body.dark .dashboard-courses .add-new-course .publish-date input ,
body.dark .dashboard-units-page .new-unit .unit-image,
body.dark .dashboard-lessons-page .new-lesson .lesson-image,
body.dark .dashboard-videos-page .new-video .bottom .video-image , 
body.dark .dashboard-units-page .new-unit .unit-name .unit-name-input,
body.dark .dashboard-lessons-page .new-lesson .lesson-name .lesson-name-input,
body.dark .dashboard-videos-page .new-video .bottom .video-name .video-name-input , 
body.dark .dashboard-units-page .new-unit .unit-name select,
body.dark .dashboard-lessons-page .new-lesson .lesson-name select,
body.dark .dashboard-videos-page .new-video .bottom .video-name select ,
body.dark .dashboard-units-page .new-unit .unit-name input.unit-created-date,
body.dark .dashboard-lessons-page .new-lesson .lesson-name input.lesson-created-date,
body.dark .dashboard-videos-page .new-video .bottom .video-name input.video-created-date,
body.dark .dashboard-exams-page .new-exam .inputs>div select,
body.dark .dashboard-exams-page .new-exam .inputs>div input ,
body.dark .dashboard-exams-page .content-container .exams-row-box .box-body > div input ,
body.dark .dashboard-exams-page .content-container .exams-row-box .box-body .right .name > div ,
body.dark .dashboard-exams-page .content-container .exams-row-box .box-body .left .enter-subscriptions-count > div input ,
body.dark .dashboard-exams-page .content-container .exams-row-box .box-body .left .price input ,
body.dark .dashboard-subscriptions-invoices .filter-invoices .date-filter input ,
body.dark .dashboard-subscriptions-invoices .invoices .add-new-invoice .new-invoice input ,
body.dark .dashboard-subscriptions-invoices .invoices .add-new-invoice .new-invoice select , 
body.dark .dashboard-subscriptions-invoices .invoices .invoice-row-box , 
body.dark .dashboard-create-copon .create-new-copon .new-copon , 
body.dark .dashboard-create-copon .create-new-copon .new-copon input , 
body.dark .dashboard-create-copon .create-new-copon .new-copon select ,
body.dark .dashboard-create-copon .resent-copons .content .copon-row ,
body.dark .dashboard-create-copon .create-new-copon , 
body.dark .dashboard-create-copon .resent-copons .section-header button.export-file ,
body.dark .dashboard-prev-copons .page-header ,
body.dark .dashboard-users-page h1 ,
body.dark .dashboard-add-user-page .add-user ,
body.dark .dashboard-add-user-page .add-user * ,
body.dark .dashboard-notifications-page .new-notification .notification-data ,
body.dark .dashboard-notifications-page .new-notification .notification-data * ,
body.dark .dashboard-notifications-page .notification-section .notification-row-box , 
body.dark .dashboard-attachments-page .attachment-section .attach-container .attachment-box{
    background-color: #0D0D0D !important;
    border-color: #FFFFFF33 !important;
}
body.dark .dashboard-courses .add-new-course .course-title-desc input::placeholder,
body.dark .dashboard-courses .add-new-course .course-title-desc textarea::placeholder , 
body.dark .dashboard-courses .add-new-course .publish-date input.course-price::placeholder , body.dark .dashboard-units-page .new-unit .unit-name .unit-name-input::placeholder,
body.dark .dashboard-lessons-page .new-lesson .lesson-name .lesson-name-input::placeholder,
body.dark .dashboard-videos-page .new-video .bottom .video-name .video-name-input::placeholder ,
body.dark .dashboard-exams-page .new-exam .inputs > div input::placeholder ,
body.dark .dashboard-exams-page .new-exam .inputs .bottom > div label , 
body.dark .dashboard-exams-page .content-container .exams-row-box .box-body .right .name p , 
body.dark .dashboard-exams-page .content-container .exams-row-box .box-body .right .image-container > p ,
body.dark .dashboard-exams-page .content-container .exams-row-box .box-body .middle > div p ,
body.dark .dashboard-exams-page .content-container .exams-row-box .box-body .left > div p ,
body.dark .dashboard-subscriptions-invoices .invoices .add-new-invoice h6 ,
body.dark .dashboard-subscriptions-invoices .invoices .invoice-row-box * ,
body.dark .dashboard-subscriptions-invoices .invoices .invoice-row-box .right .student-name p,
body.dark .dashboard-create-copon .create-new-copon .new-copon input::placeholder {
    color: #FFFFFF66 !important;
}
body.dark .dashboard-courses .add-new-course .school-year,
body.dark .dashboard-courses .add-new-course .category-type ,
body.dark .dashboard-courses .add-new-course .course-title-desc ,
body.dark .dashboard-exams-page .content-container .exams-row-box .box-body > div  {
    border-color: #FFFFFF1A;
}
body.dark .dashboard-courses .add-new-course h6 ,
body.dark .dashboard-add-user-page .add-user .left .inputs > div label , 
body.dark .dashboard-add-user-page .add-user .left h6 , 
body.dark .dashboard-add-user-page .add-user .left .permissions h6 ,
body.dark .dashboard-add-user-page .add-user .left .account-type h6,
body.dark .dashboard-notifications-page .new-notification .notification-data .top > div  label ,
body.dark .dashboard-notifications-page .notification-section .notification-row-box .date {
    color: #E1E1E180 !important;
}
body.dark .dashboard-courses .add-new-course li ,
body.dark .dashboard-subscriptions-invoices .filter-invoices .filter .invoice-type li {
    color: #E1E1E1CC !important;
    border-color: #FFFFFF33 !important;
}
body.dark .dashboard-courses .add-new-course .publish-date input.course-price {
    background-color: #1D1D1D;
}
body.dark .dashboard-courses .courses-rows {
    border-color: #FFFFFF1A !important;
}
body.dark .dashboard-courses .courses-rows .dashboard-course-box ,
body.dark .dashboard-units-page .unit-course-box,
body.dark .dashboard-row-box , 
body.dark .dashboard-section-container h6 ,
body.dark .dashboard-exams-page .content-container .exams-row-box ,
body.dark .dashboard-users-page > div .content .user-row  {
    background-color: #0D0D0D99 !important;
    border-color: #0D0D0D !important;
}
body.dark .dashboard-courses .courses-rows .dashboard-course-box * , 
body.dark .dashboard-units-page .unit-course-box *,
body.dark .dashboard-row-box * {
    border-color: #FFFFFF33 !important;
}
body.dark .dashboard-courses .courses-rows .dashboard-course-box .title-desc h6 , 
body.dark .dashboard-courses .courses-rows .dashboard-course-box .course-date p:nth-child(2) {
    color: #FFFFFF !important;
}
body.dark .dashboard-courses .courses-rows .dashboard-course-box .title-desc p  ,
body.dark .dashboard-courses .courses-rows .dashboard-course-box .course-date p:first-child,
body.dark .dashboard-units-page .unit-course-box .date-setting .date p:first-child,
body.dark .dashboard-row-box .date-setting .date p:first-child , 
body.dark .dashboard-subscriptions-invoices .filter-invoices .date-filter .from label , 
body.dark .dashboard-subscriptions-invoices .filter-invoices .date-filter .to label{
    color: #FFFFFF80 !important;
}
body.dark .dashboard-exams-page .test-type li ,
body.dark .dashboard-exams-page .content-container .exams-row-box  .row-header ,
body.dark .dashboard-subscriptions-invoices .page-title   {
    background-color: #121212;
    border-color: #FFFFFF33;
}
body.dark .dashboard-exams-page .content-container .exams-row-box .row-header .left a.add-questions , 
body.dark.dashboard-exams-page .content-container .exams-row-box .row-header .left a.add-questions i ,
body.dark .dashboard-exams-page .content-container .exams-row-box .row-header .left button.settings ,
body.dark .dashboard-exams-page .content-container .exams-row-box .row-header .left button.settings i {
    background-color: #191919;
    color: #FFFFFF;
}
body.dark .dashboard-exams-page .content-container .exams-row-box .box-body .right .image-container .new-image  {
    background-color: #121212;
}
body.dark .dashboard-exams-page .content-container .exams-row-box .box-body .right .name > div .icon i{
    background-color: #121212 !important;
}
body.dark .dashboard-exams-page .content-container .exams-row-box .box-body .left .enter-subscriptions-count > div input ,
body.dark .dashboard-exams-page .content-container .exams-row-box .box-body .left .price input {
    border: 1px solid #FFFFFF33 !important;
}
body.dark .dashboard-subscriptions-invoices .totla-incom-subscription-count .total-incom {
    background-color: #0D0D0D !important;
}
body.dark .dashboard-subscriptions-invoices .totla-incom-subscription-count .total-incom * ,
body.dark .dashboard-subscriptions-invoices .page-title {
    color: #FFFFFF !important;
}
body.dark .dashboard-subscriptions-invoices .filter-invoices .filter {
    background-color: #0D0D0D !important;
}
body.dark .dashboard-subscriptions-invoices .invoices .invoice-row-box .right .student-name h6 ,
body.dark .dashboard-subscriptions-invoices .invoices .invoice-row-box .right p , 
body.dark .dashboard-create-copon .page-header h1 ,
body.dark .dashboard-create-copon .create-new-copon h6 ,
body.dark .dashboard-prev-copons .page-header h1 , 
body.dark .dashboard-users-page h1 ,
body.dark .dashboard-users-page .section-header h6 ,
body.dark .dashboard-users-page > div .content .user-row p , 
body.dark .dashboard-users-page > div .content .user-row .left button.edit i ,
body.dark .dashboard-notifications-page h6 ,
body.dark .dashboard-notifications-page .new-notification .notification-data .middle > div p ,
body.dark .dashboard-notifications-page .new-notification .notification-data .middle > div ul li ,
body.dark .dashboard-notifications-page .new-notification .notification-data .bottom label ,
body.dark .dashboard-attachments-page .attachment-section .title h6 ,
body.dark .dashboard-attachments-page .attachment-section .attach-container .attachment-box .data .title ,
body.dark .dashboard-attachments-page .attachment-section .attach-container .attachment-box .data .price  {
    color: #fff !important;
}

body.dark .dashboard-users-page .section-header .line , 
body.dark .dashboard-attachments-page .attachment-section .title .separate {
    background-color: #FFFFFF1A;
}
body.dark .dashboard-add-user-page .add-user .add-btn button , 
body.dark .dashboard-notifications-page .new-notification .notification-data .create button {
    background-color:#0CBC8B !important;
}
body.dark .dashboard-attachments-page .attachment-section .attach-container .attachment-box .settings  button {
    background-color: #1F1F1F;
}
body.dark .dashboard-attachments-page .attachment-section .attach-container .attachment-box .settings  button i {
    color: #fff;
}
body.dark .dashboard-mobile-menu-container {
    background-color: #0D0D0D !important;
}
body.dark .dashboard-mobile-menu-container .menu-header .dark-theme {
    background-color: #FFFFFF1A;
}
/* end dashboard */
/* gharabawy */
body.dark .about-teacher-section .container .about-boxs > div ,
body.dark .gharabawy-courses .container .row > div , 
body.dark .gharabawy-courses .container .course-filter li {
    background-color: #1F1F1F;
}
body.dark .gharabawy-courses .container .row > div .content p {
    color: #Fff;
}

body.dark  .nasef-home-fetures .container> div {
    background-color: #FFFFFF1A;
}
body.dark .nasef-home-fetures .container>div .icon , 
body.dark .nasef-courses .container .courses-content .year-box .text {
    background-color: #1F1F1F;
}
body.dark  .nasef-home-fetures .container> div * {
    color: #fff;
}