:root {
    --text-color: #2a254d;
}

/* start banner */
.nasef-home {
    height: 100vh;
    background-color: #bdc3c7;
    background-image: url('../../../assets/nasef-assets/Graphic_Elements.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    margin-bottom: 25px;
    border-radius: 0 0 30px 30px;
}

.nasef-home .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.nasef-home .container .right {
    width: 50%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nasef-home .container .right .image {
    height: 400px;
    width: fit-content;
    background-color: #2c3e50;
    border-radius: 24px;
    border: 5px solid #ecf0f1;
    background-image: url('../../../assets/nasef-assets/mainsectionbg.b0f33d5bd0030b462cad.png');
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 1;
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.nasef-home .container .right .image img.circle-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    width: 80%;
}

.nasef-home .container .left {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nasef-home .container .left h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    position: relative;
    transition: all .3s ease-out 0s;
    z-index: 1;
    width: fit-content;
    padding-top: 10px;
    color: var(--text-color);
}

.nasef-home .container .left h1 img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    animation: bg 3s infinite;
    z-index: -1;
    transform: translateY(50%);
}

.nasef-home .container .left h1 .icon {
    position: absolute;
    top: -100%;
    right: 0;
    transform: rotate(30deg);
}

.nasef-home .container .left p {
    font-size: 1.8em;
    color: var(--text-color);
}

.nasef-home .container .left button.view-all-courses {
    all: unset;
    padding: 10px 25px;
    border-width: 2px 2px 5px 2px;
    border-color: #2c3e50;
    border-style: solid;
    border-radius: 14px;
    background-color: #2ecc71;
    color: #2c3e50;
    transition: all 0.5s;
    font-size: 1.1em;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 15px;
}

.nasef-home .container .left button.view-all-courses:hover {
    background-color: #27ae60;
}

.nasef-home .container .left button.go-to-login {
    all: unset;
    padding: 10px 25px;
    border-width: 2px 2px 5px 2px;
    border-color: #2c3e50;
    border-style: solid;
    border-radius: 14px;
    background-color: #e74c3c;
    color: #fff;
    transition: all 0.5s;
    font-size: 1.1em;
    font-weight: bold;
    cursor: pointer;
}

.nasef-home .container .left button.go-to-login:hover {
    background-color: #c0392b;
}

@keyframes bg {
    0% {
        width: 0;
    }

    15% {
        width: 100%;
    }

    85% {
        opacity: 1;
    }

    90% {
        width: 100%;
        opacity: 0;
    }

    100% {
        width: 0;
        opacity: 0;
    }
}

@media(max-width:767px) {
    .nasef-home {
        height: 115vh;
    }

    .nasef-home .container {
        flex-direction: column;
        justify-content: center;
    }

    .nasef-home .container .right {
        margin-bottom: 25px;
    }

    .nasef-home .container .right,
    .nasef-home .container .left {
        width: 100%;
    }

    .nasef-home .container .right .image {
        width: 100%;
        height: 300px;
        margin-bottom: 20px;
    }
}

/* end banner */
/* start fetures section */
.nasef-home-fetures {
    padding: 25px 0;
    margin: 25px 0;
}

.nasef-home-fetures .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.nasef-home-fetures .container>div {
    width: calc(95% / 4);
    border-radius: 14px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ecf0f1;
    overflow: hidden;
    /* height: 120px; */
    transition: all 0.5s;
}

.nasef-home-fetures .container>div .icon {
    position: relative;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    font-size: 3.5em;
    z-index: 1;
}

.nasef-home-fetures .container>div:nth-child(1) .icon i {
    color: #3498db;
}

.nasef-home-fetures .container>div:nth-child(2) .icon i {
    color: #2ecc71;
}

.nasef-home-fetures .container>div:nth-child(3) .icon i {
    color: #f1c40f;
}

/* .nasef-home-fetures .container>div:nth-child(4) .icon i {
    color: #3498db;
} */
.nasef-home-fetures .container>div .title {
    padding: 15px 10px;
    text-align: center;
    z-index: -1;
}

.nasef-home-fetures .container>div .title * {
    color: var(--text-color);
}

@media(max-width:767px) {
    .nasef-home-fetures .container>div {
        width: 100%;
        margin-bottom: 15px;
    }
}

.nasef-home-fetures .container>div:hover .title {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes slideInDown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        visibility: visible;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slideInDown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        visibility: visible;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

/* end fetures section */
/* start separate */
.separate {
    /* background-color: #ecf0f1; */
    background-image: url('../../../assets/Mask group.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    height: 50px;
    border-radius: 30px 30px 0 0;
    margin: 30px 0;
}

/* end separate */
/* start courses section */
.nasef-courses {
    min-height: 80vh;
    background-image: url('../../../assets/nasef-assets/Layer_1__1_-removebg-preview.png');
    background-repeat: no-repeat;
    background-position: center;
}

.nasef-courses .container .section-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nasef-courses .container .section-header h3 {
    position: relative;
    z-index: 1;
    /* background-color: #27ae60; */
    padding: 25px 25px 10px 25px;
    margin-bottom: 25px;
    cursor: pointer;
}

.nasef-courses .container .section-header h3 i {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, 50%);
    color: #f39c12;
    font-size: 1.2em;
    transition: all 0.5s;
}

.nasef-courses .container .section-header h3 img {
    width: 0;
    transition: all 0.5s;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    transform: translateY(50%);
}

.nasef-courses .container .section-header h3:hover i {
    transform: rotate(90deg);
    color: #bdc3c7;
}

.nasef-courses .container .section-header h3:hover img {
    width: 100%;
}

.nasef-courses .container .courses-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 30px 0;
}

.nasef-courses .container .courses-content .year-box {
    width: calc(95% / 3);
}

.nasef-courses .container .courses-content .year-box .image {
    height: 220px;
    overflow: hidden;
    border-radius: 24px;
    position: relative;
}

.nasef-courses .container .courses-content .year-box .image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}

.nasef-courses .container .courses-content .year-box .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    scale: 1;
    transition: all 0.5s;
}

.nasef-courses .container .courses-content .year-box .text {
    padding: 15px;
    border-radius: 14px;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transform: translateY(-20px);
    width: 80%;
    margin: 1px auto;
}

.nasef-courses .container .courses-content .year-box .text h4 {
    padding: 15px 0;
    border-bottom: 1px solid #e74c3c;
    margin-bottom: 10px;
}

.nasef-courses .container .courses-content .year-box:hover img {
    scale: 1.2;
}

.nasef-courses .container .courses-content .year-box:hover .text {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes slideInUp {
    0% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        visibility: visible;
    }

    100% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
}

@keyframes slideInUp {
    0% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        visibility: visible;
    }

    100% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
}

@media(max-width:767px) {
    .nasef-courses .container .courses-content .year-box {
        width: 100%;
        margin-bottom: 15px;
    }
}

/* end courses section */